import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { generateTheme } from 'utils/helpers';
import { ExercisesTable } from 'components/Tables/Exercises/ExercisesTable.component';
import { useUpdateProfile } from '../../../hooks/user.hook';
import { isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';

export const LifeStylePage = () => {
  const {
    patientProfile: { lifeStyle },
  } = useSelector(state => state.wnUser.data);

  const updateProfile = useUpdateProfile();

  const form = useForm({
    defaultValues: {
      maritalStatus: lifeStyle?.general?.maritalStatus ?? '',
      stressLevel: lifeStyle?.general?.stressLevel ?? '',
      physicalActivity: lifeStyle?.phisicalActivity.level ?? '',
      practiceSports: lifeStyle?.phisicalActivity?.practicesSports ?? false,
      alcohol: lifeStyle?.otherHabits?.alcohol ?? '',
      tobacco: lifeStyle?.otherHabits?.tobacco ?? '',
      sports: lifeStyle?.phisicalActivity?.sports ?? [],
      job: lifeStyle?.general?.job ?? '',
      workingHourPerWeek: lifeStyle?.general?.workingHourPerWeek ?? 0,
    },
  });

  const onSubmitHandler = form.handleSubmit(data => {
    const {
      maritalStatus,
      stressLevel,
      physicalActivity,
      practiceSports,
      sports,
      alcohol,
      tobacco,
      job,
      workingHourPerWeek,
    } = data;
    const payload = {
      lifeStyle: {
        general: {
          maritalStatus,
          stressLevel,
          job,
          workingHourPerWeek,
        },
        phisicalActivity: {
          level: physicalActivity,
          practicesSports: isEmpty(sports) ? false : practiceSports,
          sports: sports.map(({ _id, ...sport }) => ({ ...sport })),
        },
        otherHabits: {
          alcohol,
          tobacco,
        },
      },
    };
    updateProfile.mutate(payload);
  });

  const isPracticingSports = form.watch('practiceSports');

  return (
    <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
      <FormProvider {...form}>
        <form onSubmit={onSubmitHandler}>
          <Box mb={4}>
            <Typography
              variant="h6"
              color="primary"
              style={{
                color: 'black',
                textTransform: 'uppercase',
                paddingBottom: 16,
              }}
            >
              Estilo de vida general
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Controller
                  name="maritalStatus"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="maritalStatusInput">
                        Estado Civil
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="maritalStatusInput"
                        color="secondary"
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem value="Soltero">Soltero</MenuItem>
                        <MenuItem value="Casado">Casado</MenuItem>
                        <MenuItem value="En una relación">
                          En una relación
                        </MenuItem>
                        <MenuItem value="Unión libre">Unión libre</MenuItem>
                        <MenuItem value="Divorciado">Divorciado</MenuItem>
                        <MenuItem value="Viudo">Viudo</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="job"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      fullWidth
                      label="Ocupación"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="workingHourPerWeek"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <NumberFormat
                      fullWidth
                      label="Horas laborales semanales"
                      customInput={TextField}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="stressLevel"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="stressLevelInput">
                        Nivel de estrés
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="stressLevelInput"
                        color="secondary"
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem value="low">Bajo</MenuItem>
                        <MenuItem value="medium">Medio</MenuItem>
                        <MenuItem value="high">Alto</MenuItem>
                        <MenuItem value="veryHigh">Muy alto</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={4}>
            <Typography
              variant="h6"
              color="primary"
              style={{
                color: 'black',
                textTransform: 'uppercase',
                paddingBottom: 16,
              }}
            >
              Actividad Física
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Controller
                  name="physicalActivity"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="physicalActivity">
                        Nivel de actividad física
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="physicalActivity"
                        color="secondary"
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem value="none">Nulo</MenuItem>
                        <MenuItem value="veryLow">Muy ligera</MenuItem>
                        <MenuItem value="low">Ligera</MenuItem>
                        <MenuItem value="medium">Media</MenuItem>
                        <MenuItem value="high">Alto</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="practiceSports"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      label="¿Practica algún deporte o actividad física en particular?"
                      control={
                        <Checkbox
                          checked={value}
                          onChange={onChange}
                          color="secondary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          {isPracticingSports && (
            <Box mb={4}>
              <ExercisesTable />
            </Box>
          )}
          <Box mb={4}>
            <Typography
              variant="h6"
              color="primary"
              style={{
                color: 'black',
                textTransform: 'uppercase',
                paddingBottom: 16,
              }}
            >
              Otros Hábitos
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Controller
                  name="alcohol"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="alcoholInput">
                        ¿Consume alcohol regularmente?
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="alcoholInput"
                        color="secondary"
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem value="Nunca">Nunca</MenuItem>
                        <MenuItem value="Ocasionalmente (1 vez al mes)">
                          Ocasionalmente (1 vez al mes)
                        </MenuItem>
                        <MenuItem value="Regularmente (1 a 3 veces por semana)">
                          Regularmente (1 a 3 veces por semana)
                        </MenuItem>
                        <MenuItem value="Diario">Diario</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="tobacco"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="tobaccoInput">
                        ¿Fuma tabaco regularmente?
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="tobaccoInput"
                        color="secondary"
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem value="Nunca">Nunca</MenuItem>
                        <MenuItem value="Ocasionalmente (1 vez al mes)">
                          Ocasionalmente (1 vez al mes)
                        </MenuItem>
                        <MenuItem value="Regularmente (1 a 3 veces por semana)">
                          Regularmente (1 a 3 veces por semana)
                        </MenuItem>
                        <MenuItem value="Diario">Diario</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Button
            variant="contained"
            type="submit"
            disabled={updateProfile.isLoading}
          >
            Actualizar mi perfil
          </Button>
        </form>
      </FormProvider>
    </ThemeProvider>
  );
};
