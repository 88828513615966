import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, Drawer, IconButton, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { DRAWERS } from 'utils/constants';
import { useDrawer } from 'hooks/drawer.hook';
import { useStyles } from './NutritionistAppointmentDrawer.styles';
import CloseIcon from '@material-ui/icons/Close';
import { format, isBefore, sub } from 'date-fns';
import { es } from 'date-fns/locale';
import { setModalStatus } from 'redux/slices/modal.slice';
import {
  RESCHEDULE_MODES,
  setVirtualAgendaAppointmentToReschedule,
  setVirtualAgendaScheduleMode,
} from 'redux/slices/virtualAgenda.slice';
import { formatInTimeZone } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  useAcceptAppointmentSendedByNutritionist,
  useRejectAppointmentSendedByNutritionist,
} from 'hooks/user.hook';

const NutritionistAppointmentDrawer = () => {
  const [isRescheduleWarningVisible, setIsRescheduleWarningVisible] =
    useState(false);
  const [canReschedule, setCanReschedule] = useState(true);
  const [isRecheduleEnabled, setIsRescheduleEnabled] = useState(false);
  const { visibility, handleClose } = useDrawer(
    DRAWERS.nutritionistAppointment,
  );

  const { data: appointment, mode } = useSelector(
    state => state.nutritionistAppointment,
  );

  const { mutate: acceptAppointment, isLoading: isLoadingAccept } =
    useAcceptAppointmentSendedByNutritionist();

  const { mutate: rejectAppointment, isLoading: isLoadingReject } =
    useRejectAppointmentSendedByNutritionist();

  const { push } = useHistory();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const onReScheduleAppointmentHandler = () => {
    if (!isRecheduleEnabled) {
      setIsRescheduleWarningVisible(true);
      return;
    }
    batch(() => {
      dispatch(setVirtualAgendaScheduleMode(RESCHEDULE_MODES.RESCHEDULE));
      dispatch(setVirtualAgendaAppointmentToReschedule(appointment));
    });
    setTimeout(() => {
      push('/dashboard/main/create-appointment');
    }, 200);
    setTimeout(() => {
      handleClose();
    }, 700);
  };

  const onCancelAppointmentHandler = () => {
    dispatch(
      setModalStatus({
        name: 'CANCEL_APPOINTMENT_WITH_NUTRITIONIST_WARNING',
        isActive: true,
      }),
    );
  };

  const onCopyToClipboardHandler = () => {
    if (['onsite', 'home'].includes(appointment?.mode)) {
      const { street, hall, country, zipCode } = appointment.office.address;
      const address = `${street}, ${hall}, ${country}, ${zipCode}`;
      navigator.clipboard.writeText(address).then(() => {
        enqueueSnackbar('Dirección copiada con éxito', {
          variant: 'success',
        });
      });
    } else {
      navigator.clipboard
        .writeText(appointment.office?.address?.mapUrl)
        .then(() => {
          enqueueSnackbar('Dirección copiada con éxito', {
            variant: 'success',
          });
        });
    }
  };

  const onConfirmHandler = () => {
    acceptAppointment(appointment?._id ?? '');
  };

  const onCancelHandler = () => {
    rejectAppointment(appointment?._id ?? '');
  };

  const classes = useStyles();

  useEffect(() => {
    if (!isEmpty(appointment)) {
      const { rescheduleBeforeDays = 0, canReschedule } =
        appointment.nutritionist.nutritionistProfile.settings;
      const { startDate } = appointment;
      const threshold = sub(new Date(startDate), {
        hours: Number(rescheduleBeforeDays),
      });
      const isRescheduledEnabled = isBefore(new Date(), threshold);
      setIsRescheduleEnabled(isRescheduledEnabled);
      setCanReschedule(canReschedule);
    }
  }, [appointment]);

  return (
    <Drawer
      anchor="right"
      open={visibility}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box component="header">
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <Box>
          <Typography
            variant="h5"
            className={`${classes.title} ${classes.mainTitle}`}
          >
            Detalles de tu consulta
          </Typography>
          <Typography variant="h6" align="center">
            Nutriólogo: {appointment?.nutritionist?.name ?? ''}{' '}
            {appointment?.nutritionist?.lastName ?? ''}
          </Typography>
          {mode === 'CONFIRM' ? (
            <Box mt={2} component="section">
              <Button
                fullWidth
                disabled={isLoadingAccept}
                variant="contained"
                onClick={onConfirmHandler}
              >
                Confirmar cita
              </Button>
              <br />
              <br />
              <Button
                fullWidth
                disabled={isLoadingReject}
                color="primary"
                variant="outlined"
                onClick={onCancelHandler}
              >
                Rechazar cita
              </Button>
            </Box>
          ) : (
            <Box mt={2} component="section">
              {!appointment?.nutritionist?.nutritionistProfile?.canUseAgenda ? (
                <Box mb={1}>
                  <Typography variant="h6">
                    No es posible reagendar la consulta, consulta con tu
                    nutriólogo.
                  </Typography>
                  <br />
                  <Typography variant="h6">
                    No es posible cancelar la consulta, consulta con tu
                    nutriólogo.
                  </Typography>
                </Box>
              ) : canReschedule ? (
                <>
                  {isRescheduleWarningVisible && (
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ marginBottom: '16px' }}
                    >
                      ¡Uh oh! Estás fuera del margen de tiempo requerido para
                      poder modificar tu consulta. Por favor, ponte directamente
                      en contacto con tu nutriólogo (a) para consultar sus
                      políticas de reprogramación / cancelación de consultas.
                    </Typography>
                  )}
                  <Box mb={1}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={onReScheduleAppointmentHandler}
                    >
                      Reagendar
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={onCancelAppointmentHandler}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </>
              ) : (
                <Typography variant="h6" align="center">
                  Lo sentimos, no es posible reagendar ni cancelar. Si deseas
                  modificar tu consulta, por favor ponte directamente en
                  contacto con tu nutriólogo(a).
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Box pt={4} component="main">
        <Typography variant="h5" align="center">
          ¿Cuándo?
        </Typography>
        <Box my={2} className={classes.dashed}>
          <Typography variant="body2">
            {format(
              new Date(appointment?.startDate ?? null),
              "EEEE' 'd' de 'LLLL' del 'y'",
              { locale: es },
            )}
          </Typography>
          <Typography variant="body2">
            {formatInTimeZone(
              new Date(appointment?.startDate ?? null),
              appointment.timeZone,
              "'A las 'HH:mm",
              {
                locale: es,
              },
            )}
          </Typography>
          {['onsite', 'home'].includes(appointment.mode) &&
            Intl.DateTimeFormat().resolvedOptions().timeZone !==
              appointment.timeZone && (
              <Typography variant="body2">
                Recuerda, te encuentras en una zona horaria diferente a la de tu
                consulta.
              </Typography>
            )}
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" align="center">
          ¿Dónde?
        </Typography>
        <Box mt={2} className={classes.dashed}>
          <Box>
            {['onsite', 'home'].includes(appointment?.mode) ? (
              <>
                <Typography variant="body2">
                  {appointment?.office?.address?.street ?? ''},{' '}
                  {appointment?.office?.address?.hall ?? ''},{' '}
                  {appointment?.office?.address?.country ?? ''},{' '}
                  {appointment?.office?.address?.zipCode ?? ''}
                </Typography>
                <Typography variant="body2">
                  {appointment?.office?.address?.mapUrl}
                </Typography>
              </>
            ) : (
              <Typography variant="body2">
                {appointment?.office?.address?.mapUrl}
              </Typography>
            )}
            <Box mt={1}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<FileCopyIcon />}
                onClick={onCopyToClipboardHandler}
              >
                Copiar dirección{' '}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {['home', 'online'].includes(appointment.mode) && (
        <>
          <Box pt={4} component="section">
            <Typography variant="h5" align="center">
              Contacto
            </Typography>
            <Box mt={2} className={classes.dashed}>
              <Typography variant="body2">
                {appointment?.office?.phoneNumber}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Drawer>
  );
};

export default NutritionistAppointmentDrawer;
