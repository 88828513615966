import { Link as RouterLink } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { Typography, Box, Button, makeStyles, Link } from '@material-ui/core';

import { setModalStatus } from 'redux/slices/modal.slice';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  asLink: {
    color: theme.palette.action.main,
    cursor: 'pointer',
  },
}));

const TermsAndConditionsForm = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const onAcceptHandler = () => {
    dispatch(
      setModalStatus({
        name: 'FIRST_SIGN_IN_TERMS_AND_CONDITIONS',
        isActive: false,
      }),
    );
  };

  useEffect(() => {
    return () => {
      setTimeout(() => {
        dispatch(
          setModalStatus({
            name: 'FIRST_SIGN_IN',
            isActive: true,
          }),
        );
      }, 1000);
    };
  }, []);

  return (
    <Box>
      <Typography>
        Al continuar navegando en Wellnub aceptas los&nbsp;
        <Link
          to="/conditions"
          underline="none"
          target="_black"
          component={RouterLink}
          rel="noreferrer noopener"
          className={classes.asLink}
        >
          Terminos y Condiciones
        </Link>{' '}
        y{' '}
        <Link
          to="/privacy"
          underline="none"
          target="_black"
          component={RouterLink}
          rel="noreferrer noopener"
          className={classes.asLink}
        >
          Aviso de Privacidad
        </Link>
      </Typography>
      <Box textAlign="center" mt={2}>
        <Button onClick={onAcceptHandler} variant="contained" color="primary">
          Acepto Terminos y Condiciones y Aviso de Privacidad
        </Button>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsForm;
